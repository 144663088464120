<template>
  <div class="main">
    <div class="content">
      <div class="flex a-center j-between">
        <template>
          <div class="flex">
            <Button type="primary"
                    class="m-r-10"
                    v-if="checkPermi(['/admin/department/add'])"
                    @click="departVisible=true">
              <Icon custom="i-icon icon-tianjia1"
                    size="16"></Icon>
              添加
            </Button>
            <!-- <Button class="m-r-10">
              <Icon custom="i-icon icon-shangchuan"
                    size="16"></Icon>
              导出
            </Button> -->
          </div>
          <div class="cursor-pointer"
               @click.stop="onClickAdministrativeFence">
            行政围栏设置>>
          </div>
        </template>
      </div>
      <div class="flex">
        <div class="tree noScroll"
             :style="{height:tableHeight+50+'px'}">
          <MyTree :data="departmentTreeArr"
                  style="padding:0 10px"
                  @on-select-change='onSelectChange'></MyTree>
        </div>
        <div class="table">
          <Table ref="table"
                 :width="tableWidth"
                 :height="tableHeight"
                 :columns="columnsInstitution"
                 :data="data"
                 border
                 stripe
                 :loading="tableLoading"
                 :row-class-name="rowClassName"
                 @on-selection-change="onChangeSelect">
            <template slot-scope="{ row }"
                      slot="operation">
              <Button type="text"
                      v-hasPermi="['/admin/department/edit']"
                      style="color:#25bb96;padding:0 5px"
                      @click.stop="onClickEditDepart(row)">修改</Button>
              <Button type="text"
                      v-hasPermi="['/admin/department/delete']"
                      style="color:#25bb96;padding:0 5px"
                      @click.stop="onClickDeleteDepart(row)">删除</Button>
            </template>
          </Table>
          <Page class="m-t-10 m-l-10"
                :total="totalPage"
                :current="pageNum"
                :page-size="pageSize"
                show-total
                show-sizer
                show-elevator
                @on-change="onPageChange"
                @on-page-size-change="onPageSizeChange" />
        </div>
      </div>
    </div>
    <!-- 添加机构 -->
    <DepartmentModal :dataId.sync="departId"
                     v-model="departVisible"
                     @onClickConfirm="getList" @onChange='onChangClearRowStyle'></DepartmentModal>

    <!-- 行政围栏 -->
    <AdministrativeFence v-model="administrativeFenceVisible"></AdministrativeFence>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import MyTree from '@/components/common/MyTree';
import DepartmentModal from '@/components/product/admin/modal/systemSetting/DepartmentModal';
import AdministrativeFence from '@/components/product/admin/modal/systemSetting/AdministrativeFence';
import { checkPermi } from '@/utils/permission'
export default {
  components: {
    DepartmentModal,
    AdministrativeFence,
    MyTree,
  },
  data () {
    return {
      parentid: '',
      totalPage: 0,
      pageNum: 1,
      pageSize: 20,
      tableWidth: 0,
      tableHeight: 0,
      tableLoading: false,
      columnsInstitution: [{
        type: 'selection',
        width: 45,
        fixed: 'left',
        align: 'center'
      },
      {
        title: '序号',
        width: 45,
        align: 'center',
        fixed: 'left',
        render: (h, params) => {
          let index = params.index + 1
          let num = (this.pageNum - 1) * this.pageSize + index
          return h('span', num)
        }
      },
      {
        title: '机构名称',
        key: 'departName',
        tooltip: true,
        minWidth: 60,
        fixed: 'left',
        align: 'center'
      },
      {
        title: '上级机构',
        key: 'parentId',
        tooltip: true,
        minWidth: 60,
        align: 'center'
      },
      {
        title: '机构类型',
        key: 'orgType',
        tooltip: true,
        width: 90,
        align: 'center'
      },
      {
        title: '负责人',
        key: 'principalName',
        tooltip: true,
        width: 140,
        align: 'center'
      },
      {
        title: '联系电话',
        key: 'mobile',
        tooltip: true,
        width: 140,
        align: 'center'
      },
      // {
      //   title: '管理网络',
      //   key: 'gridid',
      //   tooltip: true,
      //   width: 140,
      //   align: 'center'
      // }, {
      //   title: '作业面积',
      //   key: 'workArea',
      //   tooltip: true,
      //   width: 140,
      //   align: 'center'
      // }, 
      {
        title: '备注',
        key: 'description',
        tooltip: true,
        minWidth: 60,
        align: 'center'
      },
      {
        title: '操作',
        slot: 'operation',
        width: 140,
        align: 'center'
      }
      ],
      data: [],
      selectArr: [],
      selectDataArr: [],
      selectIndexArr: [],
      selectVisitedArr: [],
      selectVisitedIndexArr: [],
      // 添加机构
      departVisible: false,
      departId: '',
      // 行政围栏
      administrativeFenceVisible: false
    };
  },
  watch: {
    currentTabIndex () {
      // this.resetSearchData()
      this.onClickSearch()
    },
    selectDataArr (newVal) {
      this.selectIndexArr = []
      if (newVal.length > 0) {
        newVal.forEach(item => {
          let data = this.data.find((items, index) => {
            return item.id == items.id
          })
          this.selectIndexArr.push(data.id)
        })
      }
    },
    selectVisitedArr (newVal) {
      console.log(newVal);
      this.selectVisitedIndexArr = []
      if (newVal.length > 0) {
        newVal.forEach(item => {
          let data = this.data.find((items, index) => {
            return item.id == items.id
          })
          this.selectVisitedIndexArr = [data.id]
        })
      }
    }
  },
  computed: {
    ...mapGetters({
      'departmentTreeArr': 'getDepartmentTreeList',
    }),
  },
  mounted () {
    this.init()
  },
  destroyed () {
    window.onresize = null
  },
  methods: {
    checkPermi,
    ...mapActions([
      'updateDepartmentTreeList',
    ]),
    init () {
      this.tableWidth = window.innerWidth - 490
      this.tableHeight = window.innerHeight - 220
      window.onresize = () => {
        this.tableWidth = window.innerWidth - 490
        this.tableHeight = window.innerHeight - 220
      }
      this.updateDepartmentTreeList()
      this.getList()
    },
    onChangeSelect (selection) {
      this.selectArr = selection.map(item => item.id)
      this.selectDataArr = selection
    },
    rowClassName (row, index) {
      if (this.selectVisitedIndexArr.includes(row.id)) {
        return 'table-visited-bgcolor'
      }
      if (this.selectIndexArr.includes(row.id)) {
        return 'table-bgcolor'
      }
    },
    // 页数改变
    onPageChange (event) {
      this.pageNum = event
      this.getList()
    },
    // 每页条数改变
    onPageSizeChange (event) {
      this.pageSize = event
      this.getList()
    },
    // 选择部门
    onSelectChange (section, data) {
      this.parentid = data.id
      this.selectDataArr = selection
      this.onClickSearch()
    },
    // 查询
    onClickSearch () {
      this.pageNum = 1
      this.getList()
    },
    onChangClearRowStyle () {
      this.selectVisitedArr = []
      
    },
    getList () {
      this.selectIndexArr = []
      this.getDepartmentList()
    },
    getDepartmentList () {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        parentid: this.parentid,
        orgtype: ''
      }
      this.tableLoading = true
      this.$http.getDepartmentList(params).then(res => {
        this.tableLoading = false
        if (res.code == 200) {
          this.totalPage = res.result.total
          this.data = res.result.depart
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 修改
    onClickEditDepart (row) {
      this.selectVisitedArr.push(row)
      this.departId = row.id
      this.departVisible = true
    },
    // 删除
    onClickDeleteDepart (row) {
      this.selectVisitedArr.push(row)
      if (row.tlEmployeeList.length > 0) {
        this.$Modal.confirm({
          title: '无法删除!',
          content: '机构下有人员，无法删除!',
          // onOk: () => {
          //   let params = {
          //     id: row.id
          //   }
          //   this.$http.deleteDepartment(params).then(res => {
          //     this.$Message.info(res.message)
          //     if (res.code === 200) {
          //       this.onChangClearRowStyle()
          //       this.getList()
          //       this.$store.dispatch('updateDepartmentTreeList', true)
          //     }
          //   })
          // },
        onCancel: () => {
          this.onChangClearRowStyle()
        },
        })
      } else {
        this.$Modal.confirm({
          title: '删除',
          content: '确认删除?',
          onOk: () => {
            let params = {
              id: row.id
            }
            this.$http.deleteDepartment(params).then(res => {
              this.$Message.info(res.message)
              if (res.code === 200) {
                this.onChangClearRowStyle()
                this.getList()
                this.$store.dispatch('updateDepartmentTreeList', true)
              }
            })
          },
        onCancel: () => {
          this.onChangClearRowStyle()
        },
        })
      }
    },
    // 点击行政围栏
    onClickAdministrativeFence () {
      this.administrativeFenceVisible = true
    }
  },
}
</script>

<style lang='scss' scoped>
@import "@/scss/iviewCssReset/adminTabs.scss";
::v-deep {
  .ivu-table .table-bgcolor td {
    background: #ebf7ff !important;
    border-right: 1px solid #ebf7ff;
  }
  .ivu-table .table-visited-bgcolor td {
    background: rgb(235, 247, 255) !important;
    border-right: 1px solid rgb(235, 247, 255);
  }
}
.main {
  width: 100%;
  height: 100%;
  padding: 16px;
  background-color: #f0f2f5;

  .topbar {
    background-color: #fff;

    .topbar-search {
      padding: 16px;
    }
  }

  .content {
    padding: 16px;
    background-color: #fff;
    /* margin-top: 16px; */

    .tree {
      margin-top: 16px;
      width: 230px;
      margin-right: 16px;
      overflow-y: auto;
      flex-shrink: 0;
      border: 1px solid #e8eaec;
    }

    .table {
      margin-top: 16px;
      flex: 1;
    }
  }
}
</style>
